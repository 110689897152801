/**
 * @flow
 */

import store from '../../store'
import router from "../../router";
import {ClaimsResponse} from "../../models/response/ClaimsResponse";
import {InternalTokensResponse} from "../../models/response/InternalTokensResponse";
import {parseJwt} from "../../helpers/parseJwt";

const state = {
    isFirstRefresh: true,
    accessToken: null,
    refreshToken: null,
    expiresIn: 0,
    tokenCreatedTime: null
};

const mutations = {
    /**
     * Sets the auth token
     *
     * @param state
     * @param token
     */
    setAuthToken: (state, token): void => state.accessToken = token,
    /**
     * Sets the refresh token
     *
     * @param state
     * @param token
     */
    setRefreshToken: (state, token): void => state.refreshToken = token,
    /**
     * Sets state to done when first access token refresh was completed
     *
     * @param state
     */
    setEndOfFirstRefresh: (state): void => state.isFirstRefresh = false,

    /**
     * Set token expiry
     *
     * @param state
     * @param seconds
     * @returns {*}
     */
    setExpiresIn: (state, seconds): void => state.expiresIn = seconds,
    /**
     * Set token created time
     *
     * @param state
     * @param time
     * @returns {*}
     */
    setTokenCreatedTime: (state, time): void => state.tokenCreatedTime = time
};

const actions = {
    /**
     * Logout
     *
     * @param commit
     */
    logout: ({commit}): void => {
        commit('setAuthToken', null);
        commit('setRefreshToken', null);
        store.commit('User/setUser', null);
        router.push({
            name: 'Login'
        })
    }
};

const getters = {
    /**
     * Get the JWT claims
     *
     * @param state
     * @returns ClaimsResponse
     */
    getClaims: (state): ClaimsResponse => {
        if (state.accessToken !== null) {
            return parseJwt(state.accessToken);
        }
        return false;
    },
    /**
     * Check the user has a token
     * @param state
     * @returns {boolean}
     */
    getHasToken: (state): boolean => state.accessToken !== null,
    /**
     * Return both access token and refresh token in one object
     *
     * @param state
     * @returns InternalTokensResponse
     */
    getTokens: (state): InternalTokensResponse => {
        return {
            accessToken: state.accessToken,
            refreshToken: state.refreshToken
        }
    },
    /**
     * Return boolean state if this is was the first refresh of the access token since window opened
     *
     * @param state
     * @returns {boolean}
     */
    getIsFirstRefresh: (state): boolean => state.isFirstRefresh,

    /**
     * Return expires in seconds
     *
     * @param state
     * @returns {null|string}
     */
    getExpiresIn: (state): ?Number => state.expiresIn,
    /**
     * Get token created time
     *
     * @param state
     * @returns {any}
     */
    getTokenCreatedTime: (state): Number => state.tokenCreatedTime
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}