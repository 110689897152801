import moment from 'moment';
import store from '../store'
import axios from 'axios'
import qs from 'qs'

import {RefreshTokenRequest} from "../models/request/RefreshTokenRequest";
import {RefreshStateModel} from "../models/state/RefreshStateModel";

export const FAILED_TO_REFRESH_VIOLATION = 'FAILED_TO_REFRESH';

export const INAPPROPRIATE_TO_REFRESH_VIOLATION = "INAPPROPRIATE_TO_REFRESH";

export const NO_ACCESS_TOKEN_VIOLATION = "NO_ACCESS_TOKEN"

/**
 * Helper function to handle the refreshing of access tokens with vuex and axios
 * @param ignoreExpiry
 * @returns {Promise<unknown>}
 */
export const handleRefreshToken = (ignoreExpiry = false) => {
    return new Promise((resolve, reject) => {
        let token = store.getters['Auth/getTokens'].accessToken;

        if (token === null || store.getters['Auth/getTokenCreatedTime'] === null) {
            return reject(NO_ACCESS_TOKEN_VIOLATION);
        }

        if (ignoreExpiry === false) {
            let tokenCreatedTime = moment.unix(store.getters['Auth/getTokenCreatedTime']);
            let tokenPlusExpiry = tokenCreatedTime.add(store.getters['Auth/getExpiresIn'], 'seconds');

            if (tokenPlusExpiry.diff(moment(), 'seconds') > 0) {
                return reject(INAPPROPRIATE_TO_REFRESH_VIOLATION);
            }
        }

        axios.post(`${process.env.VUE_APP_GRO_AUTH_URL}/connect/token`,
            qs.stringify(new RefreshTokenRequest(store.getters['Auth/getTokens'].refreshToken)), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then((res) => {
                let {
                    access_token,
                    refresh_token,
                    expires_in
                } = res.data;

                setRefreshTokenState(new RefreshStateModel(access_token, refresh_token, moment().unix(), expires_in));

                return resolve(res.data);
            })
            .catch(() => {
                setRefreshTokenState(new RefreshStateModel());

                return reject(FAILED_TO_REFRESH_VIOLATION);
            });
    });
};

/**
 * Helper function to set/clear auth state with state object
 *
 * @param refreshTokenState
 */
export const setRefreshTokenState = (refreshTokenState: RefreshStateModel) => {
    store.commit('Auth/setAuthToken', refreshTokenState.accessToken);
    store.commit('Auth/setRefreshToken', refreshTokenState.refreshToken);
    store.commit('Auth/setExpiresIn', refreshTokenState.expiresIn);
    store.commit('Auth/setTokenCreatedTime', moment().unix());
};