export class RefreshStateModel {
    constructor(accessToken = null, refreshToken = null, createdTime = null, expiresIn = null) {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
        this.createdTime = createdTime;
        this.expiresIn = expiresIn;
    }

    accessToken: string = null;
    refreshToken: string = null;
    createdTime: Number = null;
    expiresIn: Number = null
}