import _ from 'lodash'
import store from '../store';

import type {MiddlewareInterface} from "./MiddlewareInterface";
import {ClaimsResponse} from "../models/response/ClaimsResponse";

export class AuthMiddleware implements MiddlewareInterface {
    handle(to, from, next) {


        if (!_.has(to.meta, 'requireAuth')) {
            if(to.name.toLowerCase() == 'login'){
                if(!store.getters['Auth/getTokens'].accessToken){
                    next();
                    return;
                }
                else{
                    next('/portal/dashboard');
                    return;
                }
            }

            next();
            return;
        }
        else{
            if(to.name.toLowerCase() == 'login' && !store.getters['Auth/getTokens'].accessToken){
                next();
                return;
            }
        }

        const goToLogin = () => {
            next({
                name: 'Login'
            });
        };

        let claims: ClaimsResponse = store.getters['Auth/getClaims'];
        if (!claims) {
            if (_.has(to.meta, 'requireAuth')) {
                goToLogin();
            }
            return;
        }

        if (['super', 'prePaymentMember'].some(x => claims.role.includes(x))) {
            //Allow coach claims
            next();
            return;
        }

        // Didn't include coach
        store.commit('Auth/setAuthToken', null);
        store.commit('Auth/setRefreshToken', null);

        goToLogin();
    }
}