<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>
    import {eventBus} from "./helpers/eventBus";
    export default {
        name: 'App',
    }
</script>
<style lang="scss">
    @import './style/theme.scss';
    @import "~vue-material/dist/theme/engine"; // Import the theme engine
    @import "~simplebar/dist/simplebar.css";

    @include md-register-theme("default", (
            primary: md-get-palette-color(blue, A200), // The primary color of your application
            accent: md-get-palette-color(grey, 100), // The accent or secondary color
            theme: light // This can be dark or light
    ));

    @import "~vue-material/dist/theme/all";

    .md-menu-content {
        border-radius: 4px !important;
        overflow: hidden;
    }

    .text-danger {
        color: #d83e38;
    }

    #chat-view {
        ::-webkit-scrollbar {
            width: 8px;
        }

        ::-webkit-scrollbar-track {
            border-radius: 7px;
            left: 2px;
            right: 2px;
            transition: opacity .2s linear;
            opacity: 0.5;
        }

        ::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.51);
            left: 2px;
            right: 2px;
            border-radius: 8px;
        }

    }


    body {
        .md-dialog {
            z-index: 9999999;
        }

        .md-overlay {
            z-index: 999999;
        }

        .md-dialog-container {
            background: white;
        }


    }
</style>