import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import GroUI from '@gro-dev/gro-component-kit-for-web'
import '@gro-dev/gro-component-kit-for-web/dist/client/gro-ui-kit.css'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import GroUIPlugins from '@gro-dev/gro-component-kit-for-web/dist/plugins/gro-ui-kit-plugins.common'

import VTooltip from 'v-tooltip'
import Multiselect from "vue-multiselect";

import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';

Vue.use(VueToast);

Vue.use(VTooltip)
Vue.use(VueMaterial)

Vue.config.productionTip = false;
Vue.use(GroUI);


Vue.use(GroUIPlugins);

// change single option
Vue.material.locale.dateFormat = 'dd/MM/yyyy'

// change multiple options
Vue.material = {
  ...Vue.material,
  locale: {
    ...Vue.material.locale,
    dateFormat: 'dd/MM/yyyy',
    firstDayOfAWeek: 1
  }
}

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
