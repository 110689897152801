/**
 * Output to developer console when NODE environment is development
 *
 * @param args
 */
export const debugLog = (...args): void => {
    if(process.env.NODE_ENV === 'development') {
        console.log(...args);
    }
};
