import Vue from 'vue'
import Router, {Route} from 'vue-router'
import _ from 'lodash'
import {AuthMiddleware} from "./middleware/AuthMiddleware";
import {LoggingMiddleware} from "./middleware/LoggingMiddleware";
import type {MiddlewareInterface} from "./middleware/MiddlewareInterface";
import {RefreshTokenMiddleware} from "./middleware/RefreshTokenMiddleware";

Vue.use(Router);

/**
 * The middleware implementing MiddlewareInterface
 * @type {(AuthMiddleware|LoggingMiddleware)[]}
 */
const beforeEachRouterMiddleware: Array<MiddlewareInterface> = [
    new LoggingMiddleware(),
    new RefreshTokenMiddleware(),
    new AuthMiddleware()
];

export const router = new Router({
    mode: process.env.VUE_APP_MODE.includes('electron') ?  'hash' : 'history',
    scrollBehavior(to: Route, from: Route, savedPosition) {
        if (to.hash) {
            return {selector: to.hash}
        }
        return savedPosition || {x: 0, y: 0}
    },
    routes: [
        {
            path: '*',
            redirect: '/login'
        },
        {
            path: '/login',
            name: 'Login',
            component: () => import('./views/Login')
        },
        {
            path: '/process-tokens',
            name: 'ProcessTokens',
            component:() => import('./views/ProcessTokens')
        },
        {
            path: '/login/callback',
            name: 'LoginCallback',
            component: () => import('./views/LoginCallback')
        },
        {
            path: '/portal',
            name: 'Portal',
            redirect: '/portal/referrals',
            meta: {
                requireAuth: true
            },
            component: () => import('./views/Portal/PortalParentView.vue'),
            children: [
                {
                    path: 'dashboard',
                    name: 'portal.dashboard',
                    meta: {
                        requireAuth: true
                    },
                    component: () => import('./views/Portal/Pages/Dashboard.vue')
                },

                {
                    path: 'referrals',
                    name: 'portal.referrals',
                    meta: {
                        requireAuth: true
                    },
                    component: () => import('./views/Portal/Pages/Referrals.vue'),
                },
                {
                    path: 'referrals/:id',
                    name: 'portal.referrals.id',
                    meta: {
                        requireAuth: true
                    },
                    component: () => import('./views/Portal/Pages/Referrals/_id.vue')
                },
            ]
        }
    ]
});

router.beforeEach((...args) => {
    _.each(beforeEachRouterMiddleware, (item: MiddlewareInterface) => {
        item.handle(...args);
    })
});

export default router;