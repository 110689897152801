/**
 * @flow
 */
import axios from 'axios'
import store from "../store";

export const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL
});

apiClient.interceptors.request.use((config) => {
    let apiToken = store.getters['Auth/getTokens'].accessToken;
    if (apiToken) {
        config.headers.Authorization = `Bearer ${apiToken}`
    }
    return config
}, (err) => {
    return Promise.reject(err)
});

export default apiClient;
