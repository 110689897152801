import type {MiddlewareInterface} from "./MiddlewareInterface";
import {debugLog} from "../helpers/debugLog";

export class LoggingMiddleware implements MiddlewareInterface {
    handle(to, from, next) {
        debugLog("***********************");
        debugLog('RouteChanged', {
            to, from, next
        });
    }
}