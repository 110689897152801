import type {MiddlewareInterface} from "./MiddlewareInterface";

import {
    FAILED_TO_REFRESH_VIOLATION,
    NO_ACCESS_TOKEN_VIOLATION,
    handleRefreshToken
} from "../helpers/handleRefreshToken";
import {debugLog} from "../helpers/debugLog";
import _ from "lodash";

export class RefreshTokenMiddleware implements MiddlewareInterface {
    handle(to, from, next) {
        handleRefreshToken().then((res) => {
            debugLog('TokenRefreshed', res);
        }).catch((err) => {
            debugLog('RefreshTokenWarning', err);
            switch (err) {
                case NO_ACCESS_TOKEN_VIOLATION:
                case FAILED_TO_REFRESH_VIOLATION:
                    if (_.has(to.meta, 'requireAuth')) {
                        next({
                            name: 'Login'
                        })
                    }
                    return;
            }
        });
    }
}