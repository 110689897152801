import apiClient from "../../helpers/apiClient";

const state = {
    user: null,
    preferences: {
        height: '',
        weight: ''
    },
    ethnicities: null,
    goals: [],
    filter: null
};

const mutations = {
    /**
     * Set the user
     *
     * @param state
     * @param user
     */
    setUser(state, user: CoachResponse) {
        state.user = user;
    },
    setUserPreferences(state, preferences) {
        state.preferences.height = preferences.height;
        state.preferences.weight = preferences.weight
    },
    setEthnicityList(state, ethnicities) {
        state.ethnicities = ethnicities
    },
    setGoalsList(state, goals) {
        state.goals = goals
    },
    setFilters(state, filters) {
        state.filters = filters
    }
}

const actions = {
    /**
     * Gets the user,  in this case it's a coach user
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getUser({commit}) {
        const member = await apiClient.get('member')
        commit('setUser', member.data);
    },
};

const getters = {
    /**
     *
     * @param state
     * @returns Object
     */
    getUser: (state) => state.user,
    getUserPreferences: (state) => state.preferences,
    getEthnicities: (state) => state.ethnicities,
    getGoals: (state) => state.goals,
    getFilters: (state) => state.filters
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}

